/* General styles for the Account Information page */
.account-info-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 100vh;
  background-color: #ffccdd;
  color: white;
  padding: 20px;
  box-sizing: border-box;
  text-align: center;
}

/* Heading styles */
.account-info-page h2 {
  color: #61dafb;
  margin-bottom: 20px;
  text-align: center;
}

/* Form container styles */
.account-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 10px;
  background-color: white;
  width: 100%;
  max-width: 500px;
  box-sizing: border-box;
  color: #ffccdd;
}

/* Individual form group styles */
.form-group {
  margin-bottom: 15px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Label styles */
.account-form label {
  margin-bottom: 5px;
  font-weight: bold;
  text-align: left;
  width: 100%;
  color: #ffccdd;
}

/* Input field styles */
.account-form input[type="text"],
.account-form input[type="email"],
.account-form input[type="tel"],
.account-form input[type="password"] {
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100%;
  background-color: #555;
  color: #b1b0b0;
  box-sizing: border-box;
  margin-top: 5px;
}

/* Button styles */
.btn {
  background-color: #ffccdd;
  color: black;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  margin-top: 15px;
}

.btn:hover {
  background-color: #21a1f1;
}

/* Unique profile picture thumbnail for Account Information page */
.account-profile-thumbnail {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  margin: 10px 0;
  border: 3px solid #ffccdd;
}

/* File input styling */
.upload-button {
  padding: 8px 15px;
  border-radius: 5px;
  background-color: #ffccdd;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 0.9rem;
  margin-top: 10px;
}

.upload-button:hover {
  background-color: #DC73B6;
}

/* Media Query for Phone View */
@media (max-width: 768px) {
  .account-info-page {
    padding: 10px; /* Reduced padding for smaller screens */
    margin-top: 20px;
  }

  .account-info-page h2 {
    font-size: 1.5rem; /* Adjust title font size for phone view */
    margin-bottom: 10px; /* Reduced spacing */
  }

  .account-form {
    padding: 10px; /* Compact padding for smaller screens */
    max-width: 350px; /* Reduced max-width */
  }

  .form-group {
    margin-bottom: 8px; /* Reduced spacing */
  }

  .account-form input[type="text"],
  .account-form input[type="email"],
  .account-form input[type="tel"],
  .account-form input[type="password"] {
    padding: 6px; /* Compact padding */
    font-size: 0.8rem; /* Further reduced font size */
  }

  .btn {
    padding: 6px 10px; /* Smaller padding for compact buttons */
    font-size: 0.8rem; /* Reduce font size further */
  }

  .account-profile-thumbnail {
    width: 35px; /* Smaller profile picture for mobile */
    height: 35px;
  }

  .upload-button {
    padding: 5px 10px; /* Further reduced padding */
    font-size: 0.7rem; /* Reduced font size */
  }
}