/* Root Container Styles */
.store-page-container-unique {
    background-color: #ffccdd;
    color: #333;
    padding: 20px;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
  
  /* Store Header and Main Content Layout */
  .store-header-content-unique {
    display: flex;
    width: 100%;
    max-width: 1400px;
    justify-content: space-between;
    gap: 30px;
    padding: 20px 0;
    flex-wrap: nowrap;
    margin-top: 50px;
  }
  
  .store-header-content-unique.no-video {
    flex-direction: column;
    align-items: center;
  }

  /* Adjust margin when there is no video */
  .store-header-content-unique.no-video .merchandise-list-container-unique {
    margin-left: 85px; /* Increased margin for better spacing when no video */
  }
  
  /* Store Title and Video Container */
  .store-title-video-unique {
    display: flex;
    flex-direction: column;
    width: 32%;
    background-color: white;
    padding: 30px;
    border-radius: 10px;
    margin-left: -220px;
  }
  
  /* Store Header Styles */
  .store-header-unique {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  
  /* Store Title */
  .store-page-header-unique {
    font-size: 2.5rem;
    color: #61dafb;
    text-transform: uppercase;
    letter-spacing: 2px;
    text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
    border-bottom: 3px solid #ffda79;
    padding-bottom: 10px;
    margin: 0 15px;
  }
  
  /* Profile Image Styles */
  .profile-picture-header-unique {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    object-fit: cover;
    border: 2px solid #61dafb;
  }
  
  /* Video Section Styles */
  .store-video-container-unique {
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 15px;
  }
  
  .store-video-unique {
    width: 100%;
    height: auto;
    border-radius: 10px;
    pointer-events: none;
  }
  
  /* Occasion Tabs Container */
  .occasion-tabs-container-unique {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: flex-start;
    gap: 10px;
    margin-top: 15px;
    margin-bottom: 0; /* No margin at the bottom to avoid overlap */
  }
  
  /* Occasion Tabs Styles */
  .occasion-tab-unique {
    background-color: #fff0fc;
    color: black;
    height: 40px;
    padding: 10px 20px;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease;
    margin-top: 20px;
  }
  
  .occasion-tab-unique.active {
    background-color: #fff0fc;
    font-weight: bold;
  }
  
  .occasion-tab-unique:hover {
    background-color: #fdd9e5;
    color: white;
  }
  
  /* Merchandise and Content Container */
  .store-content-container-unique {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin-top: 18px;
    margin-right: 80px;
    max-width: 1000px;
    align-items: flex-start;
    padding-top: 10px; /* Additional padding to avoid overlap with tabs */
  }
  
  /* Home Button */
  .return-home-btn-unique {
    background-color: #fff0fc;
    color: black;
    border: none;
    padding: 11px;
    border-radius: 6px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease;
    align-self: flex-end; /* Align the button to the top-right */
    margin-top: 15px; /* Margin to avoid overlap with other elements */
  }
  
  .return-home-btn-unique:hover {
    background-color: #DC73B6;
  }
  
  /* Merchandise List Container */
  .merchandise-list-container-unique {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: flex-start;
    background-color: rgba(255, 255, 255, 0.9);
    padding: 25px;
    border-radius: 12px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    width: 100%;
    margin-top: 20px; /* Increase spacing to avoid overlap */
  }
  
  /* Merchandise List Items */
  .flower-item-unique {
    background-color: #fff;
    border-radius: 10px;
    padding-bottom: 35px;
    padding-left: 12px;
    width: calc(25% - 20px);
    text-align: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .flower-item-unique:hover {
    transform: scale(1.05);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  }
  
  .flower-image-unique {
    width: 80%;
    height: 320px;
    object-fit: cover;
    border-radius: 10px;
    margin-bottom: 10px;
  }
  
  .flower-details-unique {
    padding: 10px;
    color: #333;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .flower-title-unique {
    font-size: 1.2rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 5px;
  }
  
  .flower-price-unique {
    font-size: 1.2rem;
    color: #61dafb;
    margin-top: 5px;
  }
  
  /* Responsive Adjustments */
  @media (max-width: 1024px) {
    .store-page-container-unique .flower-item-unique {
      width: calc(33.333% - 15px);
    }
  
    .store-page-container-unique .store-header-content-unique {
      flex-direction: column;
      align-items: center;
    }
  
    .store-page-container-unique .store-content-container-unique {
      width: 95%;
    }
  }
    
  @media (max-width: 480px) {
    .store-page-container-unique .flower-item-unique {
      width: 48.5%;
    }
  
    
    .store-page-container-unique .occasion-tab-unique {
      padding: 8px 15px;
      font-size: 0.9rem;
    }
  }
  
  .store-header-content-unique.no-video .store-content-container-unique {
    margin-top: 0;
    align-items: center;
    width: 90%;
    padding-top: 20px;
  }
  
  @media (max-width: 768px) {
    .store-page-container-unique {
        padding: 10px;
    }

    .store-page-header-unique {
        font-size: 2rem;
    }

    .store-header-content-unique {
        flex-direction: column;
        align-items: center;
    }

    .store-title-video-unique {
        width: 90%;
        margin-left: 15px;
        margin-right: 15px;
        padding: 15px;
    }

    .store-content-container-unique {
        width: 100%;
        margin-top: 10px;
    }

    .merchandise-list-container-unique {
        padding: 15px;
        margin-left: 25px; /* Retain margin for better spacing */
    }

    /* Shrink flower item to 50% for more items display */
    .flower-item-unique {
        width: 48%; /* Adjusted to slightly below 50% to create proper spacing */
        padding: 5px; /* Reduced padding for compact layout */
        box-sizing: border-box; /* Ensure padding doesn't exceed width */
    }

    .flower-image-unique {
        height: auto; /* Maintain aspect ratio */
        width: 65%; /* Slightly reduce width for better fit */
    }

    /* Adjust button positioning and size */
    .return-home-btn-unique,
    .toggle-btn {
        font-size: 0.8rem;
        padding: 12px 12px;
        margin: 20px auto; /* Center the buttons */
        display: flex;
        justify-content: center;
    }

    .occasion-tabs-container-unique,
    .toggle-btn {
        width: 100%;
        display: flex;
        justify-content: center;
    }
    .store-header-content-unique.no-video .occasion-tabs-container-unique {
      justify-content: center; /* Center align the occasion buttons */
      gap: 15px; /* Increase spacing between buttons */
      padding-top: 10px; /* Add padding at the top */
      margin-top: 20px; /* Adjust margin for better spacing */
    }
  
    /* Individual occasion button adjustments */
    .store-header-content-unique.no-video .occasion-tab-unique {
      font-size: 0.85rem; /* Smaller font size for compact layout */
      padding: 8px 12px; /* Compact padding */
      border-radius: 6px; /* Maintain rounder edges */
      text-align: center; /* Center the text */
      margin-left: 25px;
    }
  }

