/* Root Container for Supplier Login */
.supplier-login {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background-color: #f0f8ff; /* Light blue background for a unique feel */
    color: #333;
    padding: 30px;
  }
  
  /* Header Title Styling */
  .supplier-login h1 {
    color: #0077b6; /* Deep blue for the header */
    margin-bottom: 20px;
    font-size: 2.2rem;
    font-weight: bold;
  }
  
  /* Form Styling */
  .supplier-login form {
    display: flex;
    flex-direction: column;
    background-color: #ffffff; /* White form background */
    padding-left: 50px;
    padding-top: 30px;
    padding-right: 75px;
    padding-bottom: 40px;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    width: 400px; /* Form width */
  }
  
  /* Input Fields Styling */
  .supplier-login input {
    padding: 12px;
    margin: 10px 0;
    font-size: 1rem;
    border-radius: 6px;
    border: 1px solid #ccc;
    width: 100%; /* Full width of the form */
  }
  
  /* Error Message Styling */
  .supplier-login .error {
    color: red;
    font-size: 0.9rem;
    margin-top: -10px;
    margin-bottom: 10px;
  }
  
  /* Button Styling */
  .supplier-login button {
    background-color: #0077b6; /* Consistent deep blue for buttons */
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    margin-top: 10px;
    transition: background-color 0.3s ease;
  }
  
  .supplier-login button:hover {
    background-color: #005f8a; /* Darker blue hover effect */
  }
  
  /* Sign-Up Link */
  .supplier-login p a {
    color: #0077b6;
    text-decoration: none;
  }
  
  .supplier-login p a:hover {
    text-decoration: underline;
  }
  