/* Payment Page Styles */
.payment-page {
  background-color: #ffc0cb; /* Light pink background for consistency */
  color: #333;
  padding: 20px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
}

/* Text Styles */
.payment-page h2, .payment-page h3, .payment-page h4, .payment-page p {
  font-size: 1rem;
  margin: 0.5rem;
}

.payment-page h2 {
  color: #61dafb;
  text-align: center;
  margin-bottom: 20px;
  font-size: 2rem;
}

.payment-page h3, .payment-page h4 {
  font-size: 1.6rem;
  font-weight: bold;
  color: #333;
}

/* Form Layout */
.payment-form {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1200px;
  margin: 20px auto;
  flex-direction: row;
  gap: 20px;
  box-sizing: border-box;
}

/* Left block for sender and recipient information */
.payment-left-block {
  flex: 1;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

/* Right block for order summary */
.payment-right-block {
  flex: 1;
  background-color: rgba(255, 255, 255, 0.9); /* Consistent light background */
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  box-sizing: border-box;
  color: #333;
  margin-top: 45px;
  margin-bottom: 15px;
}

/* Grouped Information Styles */
.payment-page .payment-info-group {
  border: 2px solid #61dafb;
  padding: 15px;
  margin-bottom: 15px;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.9); /* Lightened background for clarity */
  box-sizing: border-box;
}

/* Form Input Styles */
.payment-form .payment-info-group label,
.payment-form .payment-info-group input,
.payment-form .payment-info-group select {
  display: block;
  width: 100%;
  padding-top: 6px;
}

.payment-form .payment-info-group input,
.payment-form .payment-info-group select {
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 1rem;
  background-color: #fff;
  color: #333;
  box-sizing: border-box;
}

/* Smaller input boxes */
.input-row {
  display: flex;
  gap: 10px;
}

.small-input {
  flex: 1;
}

/* Form Label */
.payment-form .payment-info-group label {
  margin-bottom: 5px;
  color: #61dafb;
  font-weight: bold;
}

/* Cart Item Styles */
.payment-page .cart-item-review {
  display: flex;
  flex-direction: column; /* Aligns accessories under flower item */
  margin-bottom: 10px;
}

.payment-page .cart-item-image, 
.payment-page .cart-item-accessory-image {
  width: 60px; /* Consistent thumbnail size */
  height: 60px;
  object-fit: cover;
  margin-right: 10px;
  border-radius: 5px;
}

.payment-page .cart-item-review p {
  margin: 5px 0;
}

.payment-page .cart-item-title {
  font-size: 1rem;
  font-weight: bold;
  color: #61dafb;
}

.payment-page .cart-item-accessory-title {
  font-size: 0.9rem;
  color: #999; /* Lighter color for accessory titles */
}

/* Tip Input Styles */
.payment-page .tip-input {
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 1rem;
  background-color: #fff;
  color: #333;
  box-sizing: border-box;
}

/* Button Styles */
.payment-page .btn {
  background-color: #61dafb;
  color: black;
  border: none;
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 5px;
  margin-top: 20px;
  transition: background-color 0.3s ease;
}

.payment-page .btn:hover {
  background-color: #21a1f1;
}

/* Submit Payment Button Styles */
.payment-page .submit-payment-btn {
  background-color: #61dafb; /* Similar button color for consistency */
  color: black;
  border: none;
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 5px;
  margin-top: 20px;
  transition: background-color 0.3s ease;
}

.payment-page .submit-payment-btn:hover {
  background-color: #21a1f1; /* Hover color */
}

/* Additional Button Classes for Consistency */
.payment-page .home-btn {
  background-color: #e74c3c;
}

.payment-page .home-btn:hover {
  background-color: #c0392b;
}

.payment-page .confirm-btn {
  background-color: #4CAF50;
}

.payment-page .confirm-btn:hover {
  background-color: #45a049;
}

.payment-page .edit-btn {
  background-color: #f0ad4e;
}

.payment-page .edit-btn:hover {
  background-color: #ec971f;
}

/* Loader Styles */
.loader-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
}

.ball {
  --size: 16px;
  width: var(--size);
  height: var(--size);
  border-radius: 11px;
  margin: 0 10px;

  animation: 2s bounce ease infinite;
}

.blue {
  background-color: #4285f5;
}

.red {
  background-color: #ea4436;
  animation-delay: 0.25s;
}

.yellow {
  background-color: #fbbd06;
  animation-delay: 0.5s;
}

.green {
  background-color: #34a952;
  animation-delay: 0.75s;
}

@keyframes bounce {
  50% {
    transform: translateY(25px);
  }
}

/* Distinctive Legal Disclaimer Styles for Payment Page */
.payment-page .legal-disclaimer {
  display: flex;
  align-items: center; /* Align checkbox and text centrally */
  gap: 4px; /* Reduce the space between checkbox and text */
  font-size: 0.9rem;
  color: #333; /* Text color for readability */
  background-color: rgba(255, 255, 255, 0.9); /* Lightened background */
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 8px 12px; /* Refined padding for compact appearance */
  margin-top: 10px;
  margin-bottom: 20px;
  box-sizing: border-box;
}

.payment-page .legal-disclaimer input[type="checkbox"] {
  margin-right: 5px; /* Adjust spacing */
  margin-bottom: 15px;
  margin-left: 0px;
}

.payment-page .legal-disclaimer p {
  margin: 0;
  font-size: 0.9rem;
  color: #333;
}

/* Payment Page Styles for mobile view */
@media (max-width: 768px) {
  .payment-page {
    padding: 10px; /* Compact padding */
  }

  /* Text Styles */
  .payment-page h2 {
    font-size: 1.2rem;
    margin-bottom: 10px;
  }

  .payment-page h3, .payment-page h4, .payment-page p {
    font-size: 0.8rem; /* Reduced font size */
    margin: 0.3rem;
  }

  /* Form Layout */
  .payment-form {
    flex-direction: column; /* Stack form elements vertically */
    width: 100%; /* Full width */
    gap: 10px; /* Smaller gap */
  }

  /* Left and Right Blocks */
  .payment-left-block, .payment-right-block {
    width: 100%; /* Full width */
    margin-top: 10px;
    padding: 10px;
  }

  /* Right Block Styling */
  .payment-right-block {
    padding: 10px;
    box-shadow: none; /* Reduced shadow */
  }

  /* Grouped Information Styles */
  .payment-info-group {
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 8px;
  }

  /* Form Input Styles */
  .payment-info-group label, .payment-info-group input, .payment-info-group select {
    font-size: 0.75rem; /* Smaller font size */
  }

  .payment-info-group input, .payment-info-group select {
    padding: 8px;
    font-size: 0.75rem;
  }

  /* Smaller input boxes */
  .input-row {
    flex-direction: column; /* Stack inputs in one column */
    gap: 5px;
  }

  .small-input {
    width: 100%;
  }

  /* Cart Item Styles */
  .cart-item-review {
    margin-bottom: 8px;
  }

  .cart-item-image, .cart-item-accessory-image {
    width: 50px;
    height: 50px;
  }

  .cart-item-title {
    font-size: 0.8rem;
  }

  .cart-item-accessory-title {
    font-size: 0.7rem;
  }

  /* Tip Input Styles */
  .tip-input {
    padding: 8px;
    font-size: 0.8rem;
  }

  /* Button Styles */
  .btn {
    padding: 8px 12px;
    font-size: 0.8rem;
    width: 100%; /* Full width for mobile */
  }

  /* Submit Payment Button */
  .submit-payment-btn {
    padding: 8px 12px;
    font-size: 0.8rem;
    width: 100%;
  }

  /* Additional Buttons */
  .home-btn, .confirm-btn, .edit-btn {
    font-size: 0.75rem;
    padding: 8px 10px;
    width: 100%;
  }

  /* Legal Disclaimer */
  .legal-disclaimer {
    font-size: 0.7rem;
    padding: 6px;
  }

  .legal-disclaimer input[type="checkbox"] {
    margin-right: 5px;
  }
}