.florist-management-page {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 100vh;
  background-color: #20232a; /* Distinctive dark background */
  color: #61dafb; /* Bright accent color */
  padding: 20px;
  box-sizing: border-box;
  border: 2px solid #61dafb;
  border-radius: 10px;
  margin: 20px;
}

.florist-management-header {
  color: #ffda79;
  font-size: 2.5rem;
  text-transform: uppercase;
  letter-spacing: 2px;
  text-shadow: 3px 3px 6px rgba(0, 0, 0, 0.8);
  margin-bottom: 30px;
  border-bottom: 4px solid #61dafb;
  padding-bottom: 10px;
}

table {
  width: 100%;
  max-width: 800px;
  margin-top: 20px;
  border-collapse: collapse;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  background-color: #282c34; /* Slightly different color from background */
}

th, td {
  border: 1px solid #ddd;
  padding: 10px;
  text-align: center;
}

th {
  background-color: #333;
  color: #ffda79; /* Consistent header color */
}

/* Profile picture thumbnail adjustments */
.florist-management-thumbnail {
  width: 80px; /* Thumbnail size adjusted to fit better */
  height: 80px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid #ffda79; /* Add border to make it stand out */
}

/* Button styling to match other pages */
.btn {
  padding: 10px 20px;
  background-color: #ffccdd;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  font-size: 14px;
  margin: 10px;
}

.btn:hover {
  background-color: #DC73B6;
}

.logout-btn-unique {
  background-color: #f44336;
  margin-top: 30px;
}

.logout-btn-unique:hover {
  background-color: #e53935;
}
