.accounting {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Align items to the left */
  justify-content: flex-start; /* Start content at the top */
  text-align: left; /* Align text to the left */
  padding: 20px;
  position: relative;
}

.timeframe-toggle {
  margin-bottom: 20px;
}

.timeframe-toggle button {
  margin: 0 10px;
  padding: 10px 20px;
  border: none;
  background-color: #61dafb;
  color: white;
  cursor: pointer;
}

.timeframe-toggle button.active {
  background-color: #21a1f1;
}

.report-section {
  width: 60%;
  text-align: left; /* Align text to the left */
  font-size: 16px;
  margin: 20px 0;
  padding: 20px;
  background-color: #f4f4f4;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.report-section h2 {
  text-align: left; /* Align heading to the left */
  color: #333;
}

.report-section p {
  color: #333;
  margin-bottom: 10px;
  font-weight: bold;
}

.fee-controls {
  display: flex;
  justify-content: flex-start; /* Align controls to the left */
  gap: 15px;
  margin-top: 20px;
}

.fee-controls button {
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  font-weight: bold;
  color: white;
  background-color: #61dafb;
  border-radius: 5px;
}

.fee-controls button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.fee-controls button:hover:not(:disabled) {
  background-color: #21a1f1;
}

.report-container {
  display: flex;
  justify-content: flex-start; /* Align container content to the left */
  align-items: flex-start;
  gap: 20px; /* Add spacing between report and pie chart */
}

.report-section {
  flex: 1; /* Take up equal space */
}

.pie-chart-container {
  flex: 1; /* Take up equal space */
  max-width: 350px; /* Adjust width as needed */
  padding: 20px;
  margin-top: -5px;
}
