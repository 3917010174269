.delivery-page-unique {
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    max-width: 2000px;
    margin: 0 auto;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.delivery-header {
    text-align: center;
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 20px;
}

.delivery-content-unique {
    display: flex;
    justify-content: space-between;
    gap: 5px; /* Reduce gap between the table and the map */
}

.table-container-unique {
    flex: 2.3; /* Increase table width slightly */
    max-width: 100%;
    overflow-x: auto;
}

.map-container-unique {
    flex: 1; /* Map will remain smaller to fit well with the table */
    text-align: center;
    position: relative;
    margin-top: 20px;
}

.map-container-unique iframe {
    width: 100%;
    height: 400px;
    border-radius: 10px;
    border: 2px solid #ddd;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

table {
    width: 100%;
    border-collapse: collapse;
}

table th,
table td {
    padding: 20px;
    font-size: 14px;
    text-align: left;
    border-bottom: 1px solid #ddd;
}

table th {
    background-color: #000000;
    font-size: 14px;
}

/* Aggressive Button Overrides */
.delivery-page-unique button,
.delivery-page-unique .btn {
    padding: 10px 20px !important;
    margin-top: 10px !important;
    font-size: 14px !important;
    background-color: #007bff !important;
    color: white !important;
    border: none !important;
    cursor: pointer !important;
    border-radius: 5px !important;
    transition: background-color 0.3s ease !important;

    appearance: none !important;
    background-color: #007bff !important;
    color: white !important;
    border: none !important;
    padding-block: 10px !important;
    padding-inline: 20px !important;
    border-width: 0px !important;
    box-shadow: none !important;
    text-align: center !important;
}

.delivery-page-unique button:hover,
.delivery-page-unique .btn:hover {
    background-color: #0056b3 !important;
}

.delivery-page-unique .logout-btn-unique {
    background-color: #f44336 !important;
    margin-top: 30px !important;
}

.delivery-page-unique .logout-btn-unique:hover {
    background-color: #e53935 !important;
}

.delivery-page-unique .close-modal-btn-unique {
    background-color: #007bff !important;
    margin-top: 20px !important;
}

.delivery-page-unique .reassign-modal-unique {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
    z-index: 1000;
}

.delivery-page-unique h3 {
    font-size: 20px; /* Larger font size to stand out */
    font-weight: bold; /* Make it bold for emphasis */
    color: #333; /* Darker color for better readability */
    margin-bottom: 15px; /* Add some spacing below */
    text-align: center; /* Center-align the heading */
}

.delivery-page-unique p {
    font-size: 14px; /* Standardize font size */
    color: #555; /* Gray color for readability */
    line-height: 1.5; /* Improve text spacing */
    margin-bottom: 10px; /* Add some spacing below paragraphs */
}

.delivery-page-unique .reassign-modal-unique ul {
    list-style: none;
    padding: 0;
}

.delivery-page-unique .reassign-modal-unique li {
    margin-bottom: 8px;
    color: black;
}

.delivery-page-unique .reassign-modal-unique li button {
    margin-left: 10px !important;
    margin-right: 10px;
    font-size: 12px !important;
    background-color: #4caf50 !important;
    border: none !important;
    border-radius: 5px !important;
    padding: 5px 10px !important;
    cursor: pointer !important;
    transition: background-color 0.3s ease !important;
    width: 120px;
}

.delivery-page-unique .reassign-modal-unique li button:hover {
    background-color: #45a049 !important;
}

.visitor-count-container {
    font-size: 14px;
    font-weight: bold;
}
