/* Admin Login Page Unique Styling */
.admin-login-page-unique {
  background-color: #282c34;
  color: white;
  padding: 20px;
  margin-top: -200px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center; /* Ensure all text is centered */
}

.admin-login-page-unique h2 {
  margin-bottom: 20px;
  text-align: center; /* Ensure text is centered specifically for AdminLogin */
  width: 100%; /* Ensures the text takes the full width */
}

/* Form input fields */
.admin-login-page-unique input {
  padding: 10px;
  margin: 5px;
  font-size: 1rem;
  border-radius: 5px;
  border: none;
  background-color: #444;
  color: white;
  width: 100%;
  box-sizing: border-box;
}

.admin-login-page-unique input::placeholder {
  color: #aaa;
}

/* Button styling */
.admin-login-page-unique .btn-unique {
  background-color: #61dafb;
  color: black;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  margin-top: 10px;
}

.admin-login-page-unique .btn-unique:hover {
  background-color: #21a1f1;
}

/* Back to Home button styling */
.admin-login-page-unique .back-home-btn-unique {
  margin-top: 20px;
  background-color: #4CAF50;
}

.admin-login-page-unique .back-home-btn-unique:hover {
  background-color: #45a049;
}

/* Error Message Styling */
.admin-login-page-unique .error-unique {
  color: #ff4f4f;
  font-size: 0.9rem;
  margin-top: 10px;
}
