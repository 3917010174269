/* SignUp Page */
.signup-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: #ffc0cb;
  color: #333;
  padding: 20px;
  box-sizing: border-box;
}

/* Header styling */
.signup-page h2 {
  color: #61dafb;
  margin-bottom: 10px;
  text-align: center;
  font-size: 2rem;
}

/* Sign-up form container */
.signup-form {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 40px;
  width: 100%;
  max-width: 900px;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  margin-top: 10px;
}

/* Form side sections */
.signup-left, .signup-right {
  display: flex;
  flex-direction: column;
  width: 48%;
}

/* Input styles */
.signup-form input {
  padding: 12px;
  margin: 15px 0;
  border-radius: 5px;
  border: 1px solid #ddd;
  width: 100%;
  font-size: 1rem;
  background-color: #fff;
  color: #333;
}

/* Button styles */
.signup-btn, .signup-home-btn {
  background-color: #ffccdd;
  color: black;
  padding: 10px 20px; /* Adjusted padding */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
  font-size: 1rem;
  width: auto; /* Adjusted width */
  max-width: 150px; /* Reduced button width */
  transition: background-color 0.3s ease;
  height: auto; /* Ensuring button is not stretched vertically */
}

.signup-btn:hover, .signup-home-btn:hover {
  background-color: #DC73B6;
  color: white;
}

/* File upload styles */
.file-upload-label {
  margin-top: 15px;
  color: #333;
  font-weight: bold;
}

.file-upload-input {
  margin: 15px 0;
  padding: 10px;
  border: 1px solid #ddd;
  background-color: #fff;
  color: #333;
  border-radius: 5px;
  width: 100%;
  font-size: 1rem;
}

/* Error message styling */
.error {
  color: red;
  font-size: 0.9rem;
  margin-top: 10px;
}

/* Disclaimer Section */
.legal-disclaimer {
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.legal-disclaimer input {
  margin-right: 100px;
  transform: scale(1.1);
}

.disclaimer-text {
  font-size: 0.9rem;
  color: #333;
  line-height: 1.5;
  margin-left: -140px;
  margin-right: 40px;
  margin-top: 11px;
}

.disclaimer-text ul {
  margin-top: 10px;
  list-style-type: disc;
  padding-left: 20px;
}

.disclaimer-text ul li {
  margin-bottom: 5px;
}

/* Move buttons under the legal disclaimer */
.signup-form-buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-start; /* Align buttons to the left */
  gap: 15px; /* Added space between buttons */
  margin-top: 15px; /* Space above buttons */
}

/* Account Type Selection Spacing */
.account-type-selection {
  margin-bottom: 15px;
}

.account-type-selection p {
  margin-bottom: 10px;
  font-weight: bold;
}

.radio-option {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  margin-right: 170px;
}

.radio-option input[type="radio"] {
  margin-right: 10px; /* Adds space between the radio button and label */
}

/* Tax Info Note */
.tax-info {
  font-size: 0.9rem;
  color: #555;
  margin-top: 10px;
}

@media (max-width: 768px) {
  /* General adjustments for smaller screens */
  .signup-page {
    padding: 10px;
  }

  /* Header styling adjustments */
  .signup-page h2 {
    font-size: 1.5rem; /* Reduce font size for mobile */
    margin-top: 50px;
  }

  /* Adjust form layout for single-column display */
  .signup-form {
    flex-direction: column; /* Stack left and right sections vertically */
    align-items: center;
    gap: 20px; /* Reduce gap for compact view */
    width: 100%; /* Full width */
    max-width: 350px; /* Restrict maximum width */
    padding: 15px; /* Compact padding */
  }

  /* Adjust left and right sections */
  .signup-left,
  .signup-right {
    width: 100%; /* Full width for each section */
  }

  /* Input styling for smaller screens */
  .signup-form input {
    width: 90%; /* Ensure inputs fit within the container */
    padding: 10px; /* Compact padding */
    font-size: 0.9rem; /* Adjust font size */
  }

  /* Button adjustments */
  .signup-btn,
  .signup-home-btn {
    width: 100%; /* Full width for buttons */
    max-width: none; /* Remove max-width restriction */
    padding: 10px; /* Adjust padding for compact view */
    font-size: 0.9rem; /* Adjust font size */
  }

  /* Disclaimer Section */
  .legal-disclaimer {
    flex-direction: column; /* Stack input and text vertically */
    align-items: flex-start; /* Align to the left */
  }

  .legal-disclaimer input {
    margin-right: 10px; /* Adjust margin for better spacing */
    margin-bottom: 10px; /* Add space below checkbox */
  }

  .disclaimer-text {
    margin-left: 0; /* Remove left margin */
    margin-right: 0; /* Remove right margin */
    margin-top: 0; /* Adjust top margin */
    font-size: 0.8rem; /* Smaller font size for mobile */
    line-height: 1.4; /* Adjust line height */
  }

  /* Buttons under the legal disclaimer */
  .signup-form-buttons {
    flex-direction: column; /* Stack buttons vertically */
    align-items: center; /* Center-align buttons */
    gap: 10px; /* Adjust gap between buttons */
  }

  /* Account Type Selection */
  .account-type-selection {
    margin-bottom: 10px;
    font-size: 0.9rem; /* Adjust font size for smaller screens */
  }

  .radio-option {
    display: flex; /* Ensure label and input align in one row */
    align-items: center; /* Vertically center-align */
    justify-content: flex-start; /* Align to the left */
    margin-bottom: 5px; /* Add a consistent gap below each radio option */
    gap: 5px; /* Close the gap between radio button and label */
  }

  .radio-option input[type="radio"] {
    margin-right: 5px; /* Compact space between radio button and label */
    transform: scale(0.9); /* Slightly smaller radio buttons for mobile */
  }

  /* Tax Info Note */
  .tax-info {
    font-size: 0.8rem; /* Smaller text size */
    margin-top: 5px;
  }
}
