/* RESET & BASE STYLES */
.supplier-store * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
  
.supplier-store {
    font-family: 'Roboto', sans-serif;
    background-color: #f9f9f9;
    color: #333;
    line-height: 1.6;
    width: 100%;
    min-height: 100vh;
}
  
/* CONTAINER */
.supplier-store-container {
    max-width: 1200px;
    margin: 60px auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
  
/* HEADER */
.supplier-store-header {
    text-align: center;
    margin-bottom: 30px;
    font-size: 2.5rem;
    color: #333;
}
  
/* BUTTONS */
.supplier-store-button {
    background-color: #007bff;
    color: white;
    padding: 12px 20px;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
    margin: 10px;
}
  
.supplier-store-button:hover {
    background-color: #0056b3;
    transform: translateY(-3px);
}
  
/* TABLES */
.supplier-store-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    font-size: 1rem;
}
  
.supplier-store-table th,
.supplier-store-table td {
    padding: 15px;
    text-align: left;
    border: 1px solid #ddd;
}
  
.supplier-store-table th {
    background-color: #f4f4f4;
    font-weight: bold;
    font-size: 1.1rem;
}
  
.supplier-store-table td {
    font-size: 1rem;
}
  
/* TABLE BUTTONS */
.supplier-store-table button {
    background-color: #007bff;
    color: black;
    padding: 8px 12px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}
  
.supplier-store-table button:hover {
    background-color: #0056b3;
}
  
/* SECTIONS */
.supplier-store-products-section,
.supplier-store-orders-section {
    margin-top: 40px;
}
  
.supplier-store-products-section h2,
.supplier-store-orders-section h2 {
    font-size: 1.8rem;
    color: #444;
    margin-bottom: 20px;
}
  
/* FORM ELEMENTS */
.supplier-store-form-group {
    margin-bottom: 15px;
}
  
.supplier-store-form-group input,
.supplier-store-form-group select {
    width: 300px;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 1rem;
}
  
.supplier-store-form-group label {
    display: block;
    margin-bottom: 5px;
    font-size: 1rem;
    color: #333;
}
  
.supplier-store-form-group input:focus,
.supplier-store-form-group select:focus {
    border-color: #007bff;
    outline: none;
}
  
/* TABLE ROW HOVER EFFECT */

  
/* RESPONSIVE DESIGN */
@media (max-width: 768px) {
    .supplier-store-table {
      display: block;
      overflow-x: auto;
    }
  
    .supplier-store-container {
      padding: 10px;
      margin: 20px;
    }
  
    .supplier-store-header {
      font-size: 1.5rem;
    }
  
    .supplier-store-form-group input,
    .supplier-store-form-group select {
      width: 100%; /* Full width on smaller screens */
    }
}

/* Thumbnail image styling */
.product-thumbnail {
    width: 50px; /* Adjust size as desired */
    height: auto;
    border-radius: 4px; /* Optional for rounded corners */
  }
  