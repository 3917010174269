.supplier-profiles-container {
    padding: 20px;
    margin-top: 25px;
  }
  
  .supplier-list {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
  }
  
  .supplier-button {
    display: flex;
    align-items: center;
    padding: 10px;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    cursor: pointer;
    transition: box-shadow 0.3s;
  }
  
  .supplier-button:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .supplier-thumbnail {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 10px;
  }
  
  .supplier-info {
    text-align: left;
  }
  