/* General reset and body styling */
body, html {
  margin: 0;
  padding: 0;
  background-color: #ffccdd; /* Light pink background */
  height: 100vh; /* Full viewport height */
  overflow-x: hidden; /* Prevent horizontal scrolling */
  font-family: 'Arial', sans-serif; /* Clean, professional font */
  color: white;
}

/* Client Page Layout */
.client-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 100vh;
  background-color: transparent;
  padding: 20px;
  box-sizing: border-box;
  margin-top: 80px;
  width: 100%; /* Increase to use full viewport width */
  overflow-x: hidden;
}

/* Header styling for logo and slogan */
.client-app-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  text-align: center;
  width: 100%;
  z-index: 4;
}

.client-app-logo {
  height: 10vmin; /* Adjusted for consistency */
  width: auto;
  margin-bottom: 8px;
}

.client-slogan {
  font-size: 1.8rem;
  color: #61dafb;
  margin: 0;
  text-align: center;
  letter-spacing: 1px; /* Subtle letter spacing for a modern look */
}

/* Main Content */
.client-main-content {
  display: flex;
  width: 100%;
  max-width: 2000px;
  justify-content: space-between;
  gap: 20px; /* Reduce gap to make more space */
  align-items: flex-start;
  margin-top: -100px;
  position: relative;
  z-index: 3;
  overflow: hidden;
}

.client-left-column {
  flex: 7; /* Adjust flex to give more space to the left column */
  display: flex;
  flex-direction: column;
  padding: 100px 20px;
  border-radius: 12px;
  color: #333;
  margin-left: -15px;
  margin-bottom: 100px;
}

.client-filter-section {
  position: sticky;
  top: 0;
  z-index: 2;
  background-color: rgba(255, 255, 255, 0.9); /* Consistent light background */
  padding: 15px;
  border: 2px solid #ffccdd;
  border-radius: 10px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1); /* Shadow for emphasis */
}

.client-delivery-area {
  width: 100%;
  padding: 12px;
  font-size: 1rem;
  border: 1px solid #ffccdd;
  border-radius: 6px;
  background-color: #fff; /* White background for input */
  color: #333;
  margin-bottom: 20px;
  box-sizing: border-box;
}

.client-delivery-area::placeholder {
  color: #999; /* Softer placeholder text */
}

/* Scrollable Shop List */
.client-scrollable-shop-list {
  flex: 1;
  overflow-y: auto;
  max-height: 800px; /* Increase max height to make shop list longer */
  padding-right: 10px;
  padding-bottom: 100px;
  margin-top: 20px;
  border: 2px solid #ffccdd;
  border-radius: 8px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  background-color: rgba(255, 255, 255, 0.9);
  color: #333;
}

/* Right Column */
.client-right-column {
  flex: 1.5; /* Adjusted flex value to make right column narrower */
  background-color: rgba(255, 255, 255, 0.9); /* Lighter background */
  padding: 15px;
  margin-left: 20px; /* Adjust margin to move closer to the right */
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  position: sticky;
  top: 30px;
  color: #333;
}

/* Store Button */
.client-store-button {
  display: flex;
  align-items: center;
  background-color: #ffccdd;
  color: black;
  padding: 12px 20px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

.client-store-button img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 12px;
}

.client-store-button:hover {
  background-color: #21a1f1;
}

/* Flower List */
.client-flower-list {
  display: flex;
  flex-wrap: wrap;
  gap: 15px; /* Adjust gap for better spacing */
  width: 100%;
  padding-bottom: 10px;
  padding-top: 10px;
}

.client-flower-item {
  flex: 0 0 calc(25% - 20px); /* Ensure 4 items per row */
  max-width: 200px;
  height: 320px; /* Restore the height to original */
  border: 1px solid #ccc;
  border-radius: 10px;
  overflow: hidden;
  background-color: #ffccdd;
  color: white;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
  padding-bottom: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.client-flower-image-container {
  width: 100%;
  height: 180px; /* Adjust height for consistency */
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1px;
}

.client-flower-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.client-flower-title {
  font-size: 1.1rem;
  margin-top: 15px;
  color: #fff;
}

.client-flower-price {
  font-size: 1.3rem;
  color: #61dafb;
  margin-top: -12px;
}

.client-flower-item:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
}

/* Add to Cart Button */
.client-add-to-cart-btn {
  background-color: #61dafb;
  color: black;
  padding: 8px 12px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 0.9rem;
  margin-top: 8px;
}

.client-add-to-cart-btn:hover {
  background-color: #21a1f1;
}

/* Cart Summary */
.client-cart-summary {
  background-color: rgba(255, 255, 255, 0.9);
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 90%; /* Reduce width to prevent being cut off */
  margin-right: 0; /* Move cart summary closer to the right edge */
  margin-top: 80px;
  color: #333;
}

/* Cart Item Styling */
.client-cart-item {
  display: flex;
  flex-direction: column; /* Change to column for vertical alignment */
  align-items: flex-start; /* Align items to the start for better readability */
  justify-content: flex-start;
  padding: 10px 0;
  border-bottom: 1px solid #ccc;
}

.client-cart-item-thumbnail {
  width: 60px;
  height: 60px;
  border-radius: 5px;
  object-fit: cover;
  margin-bottom: 10px; /* Margin below the image for spacing */
}

.client-cart-item p {
  margin: 0;
  text-align: left;
  color: #333;
  display: block;
}

/* Cart Accessories */
.client-accessory-list {
  margin-top: 10px; /* Add some margin to separate from the flower item */
}

.client-accessory-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.client-accessory-thumbnail {
  width: 60px;
  height: 60px;
  border-radius: 5px;
  object-fit: cover;
  margin-bottom: 5px; /* Margin below the accessory image */
}

.client-accessory-item p {
  margin: 0;
  color: #333;
}

/* Buttons */
.client-checkout-btn,
.client-delete-item-btn {
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.client-checkout-btn {
  background-color: #ffccdd;
  color: white;
}

.client-checkout-btn:hover {
  background-color: #DC73B6;
}

.client-delete-item-btn {
  background-color: #e74c3c;
  color: white;
  border: none;
  margin-top: 10px;
  font-size: 0.85rem;
}

.client-delete-item-btn:hover {
  background-color: #c0392b;
}

/* Footer */
.client-page-footer {
  background-color: rgba(255, 255, 255, 0.7); /* Make footer less visible */
  color: #333;
  padding: 5px; /* Reduce padding to make footer less prominent */
  text-align: center;
  width: 100%;
  margin-bottom: 10px; /* Move footer closer to the bottom */
  margin-top: auto;
  position: relative;
  z-index: 1;
}

/* Pagination Buttons */
.client-pagination-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 0 10px;
  width: 100%;
}

.client-pagination-buttons button {
  background-color: #ffccdd;
  color: black;
  padding: 8px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.client-pagination-buttons button:disabled {
  background-color: #d3d3d3;
  color: #666;
  cursor: not-allowed;
  opacity: 0.85;
}

/* Modal Styling */
.client-flower-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.client-flower-modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
  max-width: 400px;
  width: 90%;
  color: #333;
}

/* Florist Shop Container */
.client-florist-shop {
  display: flex;
  flex-direction: column;
  width: 48%; /* Ensures two shops fit in a row */
  margin: 1%; /* Adjust margin to space the stores */
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  min-height: 400px; /* Ensure consistent height for empty stores */
  box-sizing: border-box;
}

/* Parent container to hold two shops side by side */
.client-florist-row {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px; /* Add spacing between the rows */
}

/* Pagination Buttons at the Bottom of Each Store */
.client-pagination-buttons {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 10px;
}

.client-pagination-buttons button:last-child {
  margin-right: 10px; /* Adds some space between the "Next" button and the right edge */
}

.client-video-item {
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 10px;
  overflow: hidden;
  background-color: #ffccdd;
  color: white;
  text-align: center;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 15px; /* Add some space below the video */
}

.client-video-container {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.client-video {
  width: 100%;
  height: auto;
  border-radius: 10px;
  pointer-events: none; /* Prevent interaction */
}

/* Adjustments for phone view */
@media (max-width: 768px) {
  /* Set client-florist-row to display in a column */
  .client-florist-row {
    flex-direction: column; /* Stacks each store vertically */
  }

  /* Ensure each store takes full width */
  .client-florist-shop {
    display: flex;
    flex-direction: column; /* Stack items vertically */
    justify-content: space-between; /* Spread items evenly */
    width: 100%; /* Full width on mobile */
    margin-bottom: 10px; /* Smaller gap between stores */
    padding: 10px; /* Compact padding */
    box-sizing: border-box;
    min-height: auto; /* Allow for dynamic height */
  }

  /* Optional: Adjust font and padding for a more compact layout */
  .client-app-header,
  .client-cart-summary,
  .client-main-content,
  .client-checkout-btn {
    padding: 10px; /* Smaller padding for compactness */
    font-size: 1rem; /* Reduced font size for better fit */
  }

  /* Make right column full-width and move below on mobile */
  .client-right-column {
    position: relative; /* Prevents it from overlapping on small screens */
    width: 100%; /* Full width on mobile */
    margin-top: 20px; /* Space above for readability */
    margin-left: 0; /* Remove margin */
  }

  .client-flower-item {
    flex: 0 0 calc(23% - 8px); /* Slightly reduced width to fit four items */
    max-width: 16%; /* Further reduced to avoid wrapping */
    height: 150px; /* Adjusted for compactness */
    padding: 7px 5px; /* Reduced padding for a tighter fit */
  }

  .client-flower-image-container {
    height: 75px; /* Reduced height for a more compact look */
    padding: 2px; /* Smaller padding around image */
    border-radius: 5px;
  }

  .client-flower-title {
    font-size: 0.8rem; /* Smaller font size for title */
    font-weight: bold; /* Bold title for emphasis */
    margin-top: -5px; /* Reduced gap between image and title */
  }

  /* Ensure pagination buttons align at the bottom */
  .client-pagination-buttons {
    margin: 0; /* Remove margin for better alignment */
  }
}
