.sales-analysis {
  text-align: center;
  padding: 20px;
  position: relative;
}

.timeframe-toggle,
.item-type-toggle {
  margin-bottom: 20px;
}

.timeframe-toggle button,
.item-type-toggle button {
  margin: 0 10px;
  padding: 10px 20px;
  border: none;
  background-color: #61dafb;
  color: white;
  cursor: pointer;
  border-radius: 5px;
}

.timeframe-toggle button.active,
.item-type-toggle button.active {
  background-color: #21a1f1;
}

.chart-container {
  width: 45%; /* Slightly larger for more chart space */
  height: 400px; /* Set a max height to avoid overly large charts */
  margin: 0 auto;
  display: inline-block;
  vertical-align: top;
  background-color: white;
  border-radius: 10px;
  padding-left: 10px;
  padding-right: 5px;
}

.visitor-counter {
  position: absolute;
  margin-top: 100px;
  top: 20px;
  right: 20px;
  background-color: #333;
  color: white;
  padding: 10px;
  border-radius: 5px;
  font-size: 14px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.performance-metrics {
  display: inline-block;
  width: 45%; /* Adjusted width to fit alongside the chart */
  vertical-align: top;
  padding-left: 20px;
  padding-bottom: 116px;
  text-align: left;
  font-size: 16px;
  background-color: white;
  margin-left: 5px;
  border-radius: 10px;
}

.performance-metrics h3 {
  margin-bottom: 10px;
  color: black;
}

.performance-metrics p {
  color: black;
  margin-bottom: 5px;
}

/* Top-selling items section styles */
.top-selling-items {
  margin-top: 30px;
  text-align: center;
}

.top-selling-items h3 {
  color: #ffffff;
  margin-bottom: 15px;
  text-align: center; /* Center align the header */
}

/* Container for aligning flower and accessory lists side by side */
.top-selling-container {
  display: flex;
  justify-content: space-between; /* Adjusted to distribute space evenly */
  align-items: flex-start; /* Align the items at the top */
  gap: 10px; /* Reduced gap between the two lists */
  margin: 0 auto; /* Center the container */
  max-width: 800px; /* Add max width for container */
  background-color: white;
  border-radius: 10px;
}

.top-selling-flowers,
.top-selling-accessories {
  flex: 1; /* Allows both sections to be of equal width */
}

.top-selling-items h4 {
  color: #61dafb;
  margin-top: 15px;
}

.top-selling-items .top-item {
  display: inline-block;
  width: 150px;
  margin: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: #333;
  min-height: 250px; /* Set a minimum height to ensure consistent size */
}

.top-item-thumbnail {
  width: 100%;
  height: auto;
  border-radius: 5px;
  margin-bottom: 10px;
}

.top-item p {
  color: #ffffff;
  font-size: 14px;
  margin: 0;
}

/* Consistent sizing for flower and accessory containers */
.top-item,
.accessory-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 250px; /* Fixed height to keep the container size consistent */
  box-sizing: border-box; /* Ensure padding and border are included in the total width and height */
}

.accessory-item p,
.top-item p {
  margin: 5px 0; /* Consistent margin for paragraphs */
}

@media (max-width: 768px) {
  /* General adjustments */
  .sales-analysis {
    padding: 10px;
  }

  .timeframe-toggle button,
  .item-type-toggle button {
    padding: 8px 15px;
    margin: 5px;
    font-size: 0.9rem;
  }

  /* Chart container adjustments */
  .chart-container {
    width: 80%; /* Shrink to 80% width */
    height: 240px; /* Reduce height proportionally */
    margin: 15px auto; /* Center the chart container */
  }

  /* Performance metrics adjustments */
  .performance-metrics {
    width: 80%; /* Shrink to 80% width */
    margin: 15px auto; /* Center and add spacing */
    padding: 15px; /* Add padding for better readability */
    font-size: 14px; /* Maintain a readable font size */
    text-align: center; /* Center-align for smaller screens */
  }

  .performance-metrics h3 {
    font-size: 1.2rem; /* Adjust heading size for readability */
    margin-bottom: 10px;
  }

  .performance-metrics p {
    margin: 8px 0; /* Reduce spacing between lines */
    font-size: 0.9rem; /* Smaller text size */
  }
}
