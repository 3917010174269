/* Unique Flower Modal Styling */
.flower-modal-unique-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7); /* Dark overlay */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.flower-modal-unique-content {
  background-color: #333; /* Dark background to match the page theme */
  color: white; /* White text for better visibility */
  border-radius: 10px;
  padding: 20px;
  width: 60%;
  max-width: 800px;
  display: flex;
  flex-direction: row;
  position: relative;
}

.flower-modal-unique-left {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.flower-modal-unique-left .flower-modal-unique-image {
  width: 80%;
  height: auto;
  max-height: 600px;
  border-radius: 10px;
  object-fit: cover;
}

.flower-modal-unique-right {
  flex: 1;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.flower-modal-unique-title {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.flower-modal-unique-description {
  font-size: 1rem;
  margin-bottom: 10px;
}

.flower-modal-unique-price {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 20px;
}

.flower-modal-unique-close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #e74c3c; /* Red for visibility */
  color: white;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  font-size: 1rem;
  cursor: pointer;
}

.flower-modal-unique-add-to-cart-btn {
  background-color: #ffccdd; /* Light blue to match the theme */
  color: black;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  align-self: flex-end;
}

.flower-modal-unique-add-to-cart-btn:hover {
  background-color: #ffccdd; /* Darker blue on hover */
}

.flower-modal-unique-accessories-list {
  list-style: none;
  padding: 0;
  margin: 10px 0;
}

.flower-modal-unique-accessory-item {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.flower-modal-unique-accessory-thumbnail {
  width: 40px;
  height: 40px;
  border-radius: 4px;
  margin-left: 10px;
  object-fit: cover;
}

.flower-modal-unique-accessory-title {
  margin-left: 10px;
}

/* Align checkbox and thumbnail next to each other */
input[type="checkbox"] {
  margin-right: 10px;
  vertical-align: middle; /* Ensure the checkbox aligns with the accessory thumbnail */
}

.flower-modal-unique-accessory-item {
  display: flex;
  align-items: center;
}

.flower-modal-unique-accessory-item input[type="checkbox"] {
  margin-right: 10px;
}

.flower-modal-unique-accessory-item .flower-modal-unique-accessory-thumbnail {
  margin-left: 25px;
  margin-top: -25px;
}

.flower-modal-unique-accessory-item .flower-modal-unique-accessory-title {
  margin-left: 25px;
}

.flower-modal-unique-accessory-quantity {
  width: 50px;
  margin-left: 10px;
}

@media (max-width: 768px) {
  .flower-modal-unique-content {
    flex-direction: column; /* Stack content vertically */
    align-items: center; /* Center-align content */
    padding: 15px;
    width: 95%; /* Adjust width for smaller screens */
    max-width: 400px; /* Restrict maximum width */
  }

  .flower-modal-unique-left,
  .flower-modal-unique-right {
    width: 100%; /* Full width for both sections */
    padding: 10px 0; /* Reduced padding for compact layout */
  }

  /* Adjusted flower image */
  .flower-modal-unique-left .flower-modal-unique-image {
    width: 80%; /* Adjust width for better fit */
    max-height: 300px; /* Constrain height */
    border-radius: 8px; /* Slightly smaller border radius */
  }

  .flower-modal-unique-title {
    font-size: 1.4rem; /* Slightly smaller font size for compact view */
    text-align: center; /* Center-align title */
    margin-top: 10px;
  }

  .flower-modal-unique-description,
  .flower-modal-unique-price {
    font-size: 1rem; /* Consistent font size */
    margin-bottom: 10px; /* Adjust spacing */
    text-align: center; /* Center-align text */
  }

  .flower-modal-unique-add-to-cart-btn {
    padding: 10px; /* Adjust padding */
    font-size: 1rem; /* Keep the text readable */
    width: 100%; /* Full-width button for ease of access */
    margin-top: 15px; /* Space above button */
  }

  .flower-modal-unique-close-btn {
    width: 30px; /* Slightly larger for easy tapping */
    height: 30px;
    font-size: 0.9rem;
    top: 10px;
    right: 10px;
  }

  /* Accessories List Adjustments */
  .flower-modal-unique-accessories-list {
    list-style: none;
    padding: 0;
    margin: 15px 0; /* Space above and below the list */
    width: 100%; /* Full width */
  }

  .flower-modal-unique-accessory-item {
    display: flex;
    align-items: center;
    justify-content: space-between; /* Spread out elements for better layout */
    margin-bottom: 10px;
    width: 100%; /* Full width */
  }

  .flower-modal-unique-accessory-thumbnail {
    width: 30px; /* Smaller thumbnail size */
    height: 30px;
    border-radius: 4px;
    object-fit: cover;
  }

  .flower-modal-unique-accessory-title {
    font-size: 0.9rem;
    flex: 1; /* Allow title to take remaining space */
    margin-left: 10px; /* Space between thumbnail and title */
  }

  .flower-modal-unique-accessory-quantity {
    width: 40px; /* Compact input box for quantity */
    padding: 4px;
    font-size: 0.8rem; /* Adjust font size */
    text-align: center;
  }

  .flower-modal-unique-accessory-item input[type="checkbox"] {
    transform: scale(0.9); /* Slightly smaller checkbox */
    margin-right: 10px; /* Space between checkbox and thumbnail */
  }
}