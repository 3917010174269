/* Scoped Supplier SignUp Styles */
.supplier-signup-page {
  padding: 30px;
  background-color: #f4f7f9; /* Softer background color */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.supplier-signup-page .supplier-signup-form {
  display: flex;
  justify-content: space-between;
  max-width: 1000px;
  width: 100%;
  background-color: #ffffff;
  padding: 50px 70px 50px 50px;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.1); /* Slightly stronger shadow */
  border-radius: 10px; /* Slightly more rounded corners */
  gap: 20px; /* Adds gap between left and right form sections */
}

.supplier-signup-page .supplier-signup-left,
.supplier-signup-page .supplier-signup-right {
  display: flex;
  flex-direction: column;
  gap: 15px; /* Increase gap between form fields */
  width: 48%; /* Make form sides take up equal space */
}

.supplier-signup-page input[type="text"],
.supplier-signup-page input[type="email"],
.supplier-signup-page input[type="tel"],
.supplier-signup-page input[type="password"],
.supplier-signup-page .file-upload-input {
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 6px; /* More rounded input fields */
  width: 100%;
  font-size: 16px;
}

.supplier-signup-page input:focus {
  border-color: #5cb85c; /* Green border on focus */
  outline: none;
}

.supplier-signup-page .signup-btn {
  background-color: #5cb85c;
  color: white;
  padding: 12px 18px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease; /* Add transition for smooth hover effect */
}

.supplier-signup-page .signup-btn:hover {
  background-color: #4cae4c;
}

.supplier-signup-page .signup-btn:disabled {
  background-color: #ccc;
}

.supplier-signup-page .signup-home-btn {
  margin-top: 25px;
  background-color: #0275d8;
  color: white;
  padding: 12px 18px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.supplier-signup-page .signup-home-btn:hover {
  background-color: #025aa5;
}

.supplier-signup-page .legal-disclaimer {
  margin-top: -20px;
  font-size: 14px;
  color: #333; /* Darker font for better readability */
  display: flex;
  align-items: flex-start; /* Align the checkbox with the top of the text */
  gap: 10px; /* Add space between checkbox and text */
  margin-bottom: 15px;
}

.supplier-signup-page .legal-disclaimer input[type="checkbox"] {
  margin-top: 3px; /* Adjust checkbox position */
  margin-left: 30px;
  margin-right: 150px;
  margin-top: 15px;
}

.supplier-signup-page .legal-disclaimer ul {
  list-style-type: none;
  padding-left: 0;
  margin: 0;
}

.supplier-signup-page .error {
  color: red;
  margin-top: 12px;
  font-size: 14px;
}

.supplier-signup-page .file-upload-label {
  font-size: 14px;
  color: #555;
  margin-bottom: 5px;
}

.supplier-signup-page .file-upload-input {
  font-size: 14px;
  color: #555;
}
