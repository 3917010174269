/* CRM.css */

/* Reset default user-agent styles */
.crm-page-unique * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: Arial, sans-serif; /* Explicit font to avoid user agent font styles */
}

/* General styles for the CRM page */
.crm-page-unique {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    min-height: 100vh;
    background-color: #ffccdd; /* Dark background color */
    color: white; /* Change text color to white */
    padding: 20px;
    box-sizing: border-box;
    border: 2px solid white; /* Border around the CRM page */
    border-radius: 10px; /* Rounded corners */
    margin-top: 120px; /* Space around the form */
    margin-left: 20px;
    margin-right: 20px;
}

/* Heading styles */
.crm-page-unique h2 {
    color: #61dafb; /* Light blue for heading */
    margin-bottom: 20px;
    margin-top: 40px;
    text-align: center;
    width: 100%;
    font-weight: bold; /* Ensure consistent heading font weight */
    font-size: 2rem; /* Explicit font size */
}

/* No orders message styles */
.no-orders-unique {
    font-size: 1.2rem;
    margin-top: 20px;
    color: white; /* Match text color */
    text-align: center;
}

/* Table styles */
.crm-table-unique {
    width: 100%;
    max-width: 800px;
    margin-top: 20px;
    border-collapse: collapse;
    border: 1px solid #ddd; /* Table border */
    border-radius: 8px;
    overflow: hidden; /* Ensure rounded corners */
    background-color: #333; /* Consistent background */
}

.crm-table-unique th,
.crm-table-unique td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
    color: white; /* White text in table */
    font-size: 1rem; /* Explicit font size */
    font-weight: normal; /* Override any bold from user agent */
}

.crm-table-unique th {
    background-color: #333; /* Dark background for headers */
    font-weight: bold; /* Explicit header font weight */
    text-transform: uppercase; /* Ensure headers are uppercase */
}

.crm-table-unique tr:nth-child(even) {
    background-color: #444; /* Alternate row background */
}

.crm-table-unique tr:hover {
    background-color: #555; /* Hover effect */
}

/* Button styles */
.view-btn-unique,
.hide-btn-unique,
.toggle-status-btn-unique {
    background-color: #61dafb;
    color: black;
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 0.9rem;
    margin-right: 5px;
    font-weight: bold; /* Ensure bold text on buttons */
    text-transform: uppercase; /* Buttons text uppercase */
}

.view-btn-unique:hover,
.hide-btn-unique:hover,
.toggle-status-btn-unique:hover {
    background-color: #21a1f1;
    color: white;
}

/* Close button for the order details */
.close-btn-unique {
    background-color: #f44336;
    color: white;
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 0.9rem;
    margin-top: 10px;
    text-transform: uppercase; /* Close button text uppercase */
}

.close-btn-unique:hover {
    background-color: #d32f2f;
}

/* Delivered button */
.delivered-btn-unique {
    background-color: #4CAF50;
    color: white;
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 0.9rem;
    margin-right: 5px;
    text-transform: uppercase; /* Delivered button text uppercase */
}

.delivered-btn-unique:hover {
    background-color: #45a049;
}

/* Scope input, button, and select to CRM only */
.crm-page-unique input, .crm-page-unique button, .crm-page-unique select {
    font-family: inherit;
    font-size: inherit;
    color: inherit;
    background-color: inherit;
    border: 1px solid white; /* Ensure border styles */
    border-radius: 4px;
    padding: 8px; /* Explicit padding */
    margin: 4px 0; /* Consistent margin */
}

.order-item-container {
    display: flex;
    justify-content: flex-start;
    align-items: stretch; /* Ensures both items stretch to the same height */
    gap: 20px; /* Adds space between flower and accessory */
    margin-bottom: 20px; /* Space between different cart items */
    margin-top: 15px;
}

.order-item,
.accessory-item {
    background-color: white;
    padding: 15px; /* Equal padding for both */
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    flex-grow: 1; /* Ensure both boxes grow equally */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 200px; /* Adjust the width as needed */
}

.order-item-thumbnail,
.accessory-item-thumbnail {
    width: 100%;
    height: auto;
    margin-bottom: 10px;
}

.accessory-list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px; /* Space between accessories */
}

.order-details-unique {
    margin-top: 15px;
}