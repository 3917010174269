/* NavbarUnique.css */

/* Unique Navbar */
.navbar-unique {
  height: 60px; /* Define a fixed height for the navbar */
  width: 100%;
  background-color: transparent;
  padding: 0px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Adjusts the layout to accommodate the logo and profile section */
.navbar-content-unique {
  display: flex;
  justify-content: space-between;
  width: 100%; /* Take full width */
  align-items: center;
}

.navbar-content-unique h3 {
  font-size: 40px;
}

/* Logo section */
.logo-container-unique {
  display: flex;
  align-items: center;
}

.app-logo-unique {
  height: 100px; /* Increase logo size */
  width: 100px;
  object-fit: contain; /* Ensure logo scales nicely */
  margin-right: 20px; /* Keep space between logo and other elements */
}

/* Right Section */
.navbar-right-section {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px; /* Adds spacing between cart and profile */
  position: relative;
}

/* Profile section */
.profile-section-unique {
  position: relative;
  z-index: 1001; /* Ensure it's on top of other content */
}

/* Profile button */
.profile-button-unique {
  display: flex;
  align-items: center;
  background-color: white;
  color: black;
  border: none;
  padding: 5px 25px;
  cursor: pointer;
  border-radius: 5px;
  outline: none;
  font-size: 0.85rem;
  margin-right: 40px;
}

/* Profile picture */
.profile-picture-unique {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 5px;
}

/* Dropdown menu */
.dropdown-menu-unique {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #333;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1001;
  display: flex;
  flex-direction: column;
  padding: 5px 15px;
  margin-right: 40px;
}

.dropdown-item-unique {
  padding: 10px;
  color: white;
  text-decoration: none;
  cursor: pointer;
  font-size: 0.85rem;
}

.dropdown-item-unique:hover {
  background-color: #444;
}

/* Shopping Cart Section */
.shopping-cart-section {
  display: flex;
  align-items: center;
  position: relative;
}

.shopping-cart-button {
  background: none;
  border: none;
  cursor: pointer;
}

.shopping-cart-icon {
  width: 30px;
  height: 30px;
}

/* Cart Dropdown */
.dropdown-cart-unique {
  position: absolute;
  right: 0;
  top: 100%;
  width: 400px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 15px;
  z-index: 1000;
  color: black;
}

/* Cart item and accessories styling */
.cart-item, .cart-accessory {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

/* Cart item image */
.cart-item-image, .accessory-thumbnail {
  width: 70px;
  height: 70px;
  margin-right: 10px;
  border-radius: 5px;
}

/* Cart item and accessory text */
.cart-item p, .cart-accessory span {
  font-size: 0.85rem;
  color: black;
  margin: 0;
  line-height: 1.5;
}

/* Accessory list */
.accessory-list {
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

/* Accessory text and image alignment */
.cart-accessory {
  display: flex;
  align-items: center;
  gap: 10px;
}

/* Checkout button */
.checkout-button {
  background-color: #ffccdd;
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
}

.checkout-button:hover {
  background-color: #dc73b6;
}

/* Delete cart item button */
.delete-cart-item-button {
  background-color: #e74c3c;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 10px;
  font-size: 0.85rem;
}

.delete-cart-item-button:hover {
  background-color: #c0392b;
}

/* Profile Dropdown item */
.dropdown-menu-unique button {
  background-color: transparent;
  border: none;
  color: white;
  font-size: 0.85rem;
  cursor: pointer;
  text-align: left;
  padding: 10px;
  width: 100%;
}

.dropdown-menu-unique button:hover {
  background-color: #444;
}

/* Adjustments for phone view */
@media (max-width: 768px) {
  /* Navbar unique */
  .navbar-unique {
    height: 50px; /* Reduce height for mobile */
    padding: 0 10px; /* Smaller padding for compactness */
  }

  .navbar-content-unique h3 {
    font-size: 1.5rem; /* Smaller font size for title */
  }

  /* Logo */
  .logo-container-unique {
    margin-right: 10px; /* Reduced spacing around logo */
  }

  .app-logo-unique {
    height: 50px; /* Smaller logo for mobile */
    width: 50px;
  }

  /* Navbar right section */
  .navbar-right-section {
    gap: 10px; /* Reduced gap between items */
  }

  /* Profile section */
  .profile-button-unique {
    padding: 4px 15px; /* Smaller padding for compactness */
    font-size: 0.75rem; /* Reduced font size */
    margin-right: 20px; /* Narrower margin */
  }

  .profile-picture-unique {
    width: 30px;
    height: 30px; /* Smaller profile picture */
    margin-right: 5px;
  }

  /* Dropdown menu */
  .dropdown-menu-unique {
    padding: 5px 10px; /* Compact padding */
  }

  .dropdown-item-unique {
    font-size: 0.75rem; /* Smaller font size */
    padding: 8px; /* Reduced padding */
  }

  /* Shopping Cart Section */
  .shopping-cart-icon {
    width: 25px; /* Smaller cart icon */
    height: 25px;
  }

  /* Cart dropdown */
  .dropdown-cart-unique {
    width: 280px; /* Adjust width for mobile */
    padding: 10px;
    left: 50%; /* Center horizontally */
    transform: translateX(-50%); /* Center alignment on small screens */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Optional: add shadow for distinction */
  }

  .cart-item, .cart-accessory {
    margin-bottom: 8px; /* Smaller margin between items */
  }

  .cart-item-image, .accessory-thumbnail {
    width: 50px; /* Smaller image for compactness */
    height: 50px;
  }

  .cart-item p, .cart-accessory span {
    font-size: 0.75rem; /* Smaller font size */
  }

  /* Accessory list */
  .accessory-list {
    gap: 3px; /* Reduced gap between accessories */
  }

  /* Checkout button */
  .checkout-button {
    padding: 6px 10px; /* Smaller padding */
    font-size: 0.85rem; /* Reduced font size */
  }

  /* Delete cart item button */
  .delete-cart-item-button {
    padding: 4px 8px; /* Smaller padding */
    font-size: 0.75rem; /* Reduced font size */
  }

  /* Profile dropdown item */
  .dropdown-menu-unique button {
    font-size: 0.75rem; /* Smaller font size */
    padding: 8px; /* Reduced padding for compactness */
  }
}