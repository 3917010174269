/* Store Information Page (Unique Styles) */
.store-info-page-unique {
  display: flex;
  flex-direction: column; /* Ensures that the h2 stays on top */
  align-items: center;
  justify-content: flex-start;
  min-height: 100vh;
  background-color: #ffccdd;
  color: white;
  padding: 20px;
  box-sizing: border-box;
}

/* Store Content Container: for both the left and right blocks */
.store-content-unique {
  display: flex;
  flex-direction: row; /* Arrange left and right sections side by side */
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  max-width: 1200px;
  gap: 50px; /* Space between the form and the item list */
}

/* Header (florist store info) styling */
h2 {
  text-align: center;
  margin-bottom: 20px;
  font-size: 2.5rem;
  color: #61dafb;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8);
  border-bottom: 3px solid #ffda79;
  padding-bottom: 10px;
}

/* Left block for the upload form */
.store-left-block-unique {
  flex: 1;
  background-color: #333;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: flex-start;
}

/* Right block for the item list */
.store-right-block-unique {
  flex: 2;
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Advanced Settings Section */
.advanced-settings-toggle {
  background-color: #ffccdd;
  color: black;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  text-align: center;
  margin: 20px 0;
}

.advanced-settings-content {
  display: none; /* Hidden by default */
  flex-direction: column;
  gap: 15px;
  width: 100%;
}

/* Form inside the left block */
.store-form-unique {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%; /* Ensure form takes full width */
}

.form-group-unique {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

label-unique {
  color: #61dafb;
  font-weight: bold;
}

input-unique {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 1rem;
  background-color: #444;
  color: white;
}

/* Add/Upload Button */
.upload-button-unique {
  background-color: #ffccdd;
  color: black;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  margin-top: 15px;
}

.upload-button-unique:hover {
  background-color: #DC73B6;
}

/* Merchandise list inside the right block */
.item-list-unique {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 15px;
  width: 100%; /* Ensure list takes full width */
}

.item-unique {
  width: 240px;
  border: 1px solid #ccc;
  border-radius: 10px;
  overflow: hidden;
  background-color: #444;
  color: white;
  text-align: center;
  transition: transform 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  box-sizing: border-box;
}

.item-unique:hover {
  transform: scale(1.05);
}

.item-image-unique {
  width: 120px;
  height: 120px;
  border-radius: 15%;
  object-fit: cover;
  padding-top: 12px;
  margin-bottom: 10px;
}

/* Delete and Share Buttons */
.delete-btn-unique,
.share-btn-unique {
  width: 100%;
  margin-top: 10px;
  padding: 5px 0;
  background-color: #ffccdd;
  color: black;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 0.9rem;
  text-align: center;
}

.delete-btn-unique:hover {
  background-color: #e53935;
  color: white;
}

.share-btn-unique:hover {
  background-color: #DC73B6;
  color: white;
}

/* Toggle buttons for adding flowers or accessories */
.toggle-buttons-unique {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-bottom: 20px;
}

.toggle-buttons-unique button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: #ffccdd;
  color: black;
  font-size: 1rem;
}

.toggle-buttons-unique button.active {
  background-color: #DC73B6;
  font-weight: bold;
}

/* Occasion tabs styling (side by side) */
.occasion-tabs-unique {
  display: flex;
  flex-wrap: wrap; /* Ensures tabs stay on one line if space is available */
  justify-content: flex-start;
  gap: 10px;
  margin-bottom: 20px;
}

.occasion-tab-unique {
  background-color: #ffccdd;
  color: black;
  padding: 10px 20px;
  border-radius: 25px; /* Rounder edges for a smoother look */
  cursor: pointer;
  font-size: 1rem;
  text-align: center;
  position: relative; /* To allow positioning the delete button */
  display: inline-flex;
  align-items: center;
}

.occasion-tab-unique.active {
  background-color: #DC73B6;
  font-weight: bold;
}

.occasion-tab-unique:hover {
  background-color: #DC73B6;
  color: white;
}

/* Smoother delete button for tabs */
.delete-occasion-btn-unique {
  background-color: #e53935;
  color: white;
  border: none;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  font-size: 0.75rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-left: 8px; /* Keep the delete button inside the tab */
  transition: background-color 0.3s ease;
}

.delete-occasion-btn-unique:hover {
  background-color: #c0392b;
}

/* Reduce gap between tab text and delete button */
.occasion-tab-unique .delete-occasion-btn-unique {
  margin-left: 8px;
}

/* Online/Offline Toggle Button Styling */
.store-status-unique {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  margin-bottom: 20px;
}

.store-status-unique p {
  font-size: 1.2rem;
  color: #ffccdd;
  background-color: white;
  border-radius: 5px;
  padding: 10px;
}

.toggle-button-unique {
  padding: 10px 25px;
  border-radius: 25px;
  font-size: 1rem;
  cursor: pointer;
  border: none;
  background-color: #fff0fc;
  color: black;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.toggle-button-unique.online {
  background-color: #00b894; /* Green for online status */
  color: white;
  font-weight: bold;
}

.toggle-button-unique.offline {
  background-color: #e74c3c; /* Red for offline status */
  color: white;
  font-weight: bold;
}

.toggle-button-unique:hover {
  transform: scale(1.1);
  background-color: #DC73B6; /* Hover color for both states */
}

/* New Toggle Switch Style From Uiverse.io by andrew-demchenk0 */
.switch {
  --input-focus: #2d8cf0;
  --bg-color: #fff;
  --bg-color-alt: #666;
  --main-color: #323232;
  --input-out-of-focus: #ccc;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
  width: 70px;
  height: 36px;
  transform: translateX(calc(50% - 10px));
}

.toggle {
  opacity: 0;
}

.slider {
  box-sizing: border-box;
  border-radius: 100px;
  border: 2px solid var(--main-color);
  box-shadow: 4px 4px var(--main-color);
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--input-out-of-focus);
  transition: 0.3s;
}

.slider:before {
  content: "off";
  box-sizing: border-box;
  height: 30px;
  width: 30px;
  position: absolute;
  left: 2px;
  bottom: 1px;
  border: 2px solid var(--main-color);
  border-radius: 100px;
  background-color: var(--bg-color);
  color: var(--main-color);
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  line-height: 25px;
  transition: 0.3s;
}

.toggle:checked + .slider {
  background-color: var(--input-focus);
  transform: translateX(-32px);
}

.toggle:checked + .slider:before {
  content: "on";
  transform: translateX(32px);
}

@media (max-width: 768px) {
  .store-content-unique {
    flex-direction: column; /* Stack left and right blocks vertically */
    align-items: center; /* Center-align both blocks */
    gap: 20px; /* Space between blocks */
  }

  /* Left Block Adjustments */
  .store-left-block-unique {
    width: 100%; /* Full width on mobile */
    max-width: 350px; /* Restrict maximum width */
    padding: 15px; /* Compact padding */
    gap: 15px; /* Adjust gap between elements */
    font-size: 0.85rem; /* Slightly smaller text size */
  }

  .store-form-unique {
    width: 100%; /* Ensure form spans within the block */
    gap: 12px; /* Compact spacing between form groups */
  }

  .form-group-unique {
    display: flex;
    flex-direction: column;
    gap: 10px; /* Adjust gap between label and input */
    width: 100%; /* Ensure elements fit the block */
  }

  label-unique {
    font-size: 0.85rem; /* Smaller label font size */
    text-align: left; /* Align text for readability */
  }

  input-unique {
    width: 90%; /* Ensure input fits within the block */
    padding: 8px; /* Adjust padding for smaller screens */
    font-size: 0.85rem; /* Smaller font size */
    border: 1px solid #ddd;
    border-radius: 5px;
    box-sizing: border-box; /* Prevent overflow */
  }

  .upload-button-unique {
    width: 100%; /* Full width for buttons */
    padding: 10px; /* Compact padding */
    font-size: 0.85rem; /* Adjust font size */
  }

  .toggle-buttons-unique button {
    width: 100%; /* Full width for toggle buttons */
    padding: 10px 15px;
    font-size: 0.85rem;
  }

  /* Right Block Restored to Original Layout */
  .store-right-block-unique {
    flex: 2; /* Restore proportion of the right block */
    width: 100%;
    max-width: 700px; /* Control maximum width for large screens */
    padding: 20px; /* Original padding */
    font-size: 1rem; /* Restore font size */
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }

  .item-list-unique {
    display: flex;
    flex-wrap: wrap;
    gap: 12px; /* Restore spacing between items */
    justify-content: flex-start; /* Align items to the left */
  }

  .item-unique {
    width: calc(45% - 10px); /* Adjust item width for better visibility */
    max-width: 200px; /* Restrict maximum width */
    text-align: center;
    border: 1px solid #ddd;
    border-radius: 10px;
    background-color: #444;
    color: white;
    padding: 10px;
    box-sizing: border-box;
    transition: transform 0.3s ease;
  }

  .item-unique:hover {
    transform: scale(1.05); /* Slight zoom effect on hover */
  }

}
