.chat-history-page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  
  color: white; /* Text color to ensure readability */
  padding: 20px;
  box-sizing: border-box;
}

.chat-history-title {
  margin-bottom: 20px;
}

.chat-history-list {
  width: 100%;
  max-width: 600px; /* Set a max width for the chat box */
}

.chat-header-info {
  background-color: #444; /* Darker box for header info */
  padding: 15px;
  border-radius: 10px;
  margin-bottom: 15px;
  border: 1px solid #61dafb;
  color: #61dafb;
}

.chat-messages-box {
  background-color: #333; /* Slightly lighter background for chat items */
  border: 1px solid #61dafb; /* Blue border around chat box */
  border-radius: 10px;
  padding: 15px;
  max-width: 600px; /* Consistent max width for chat history items */
  box-sizing: border-box;
  height: 400px;
  overflow-y: scroll; /* Add scroll to the messages box */
}

.chat-message-item {
  margin-bottom: 15px; /* Space between messages */
  padding: 10px;
  background-color: #444; /* Darker box for each message */
  border-radius: 5px;
  color: white;
  word-break: break-word; /* Ensure long messages wrap correctly */
}

.message-timestamp {
  font-size: 12px;
  color: #aaa; /* Lighter color for timestamps */
  margin-top: 5px;
  text-align: right; /* Align timestamps to the right */
}

.chat-history-input-box {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 600px;
  margin-top: 20px;
}

.chat-history-input-box input[type="text"] {
  padding: 10px;
  width: 100%;
  border: 1px solid #61dafb;
  border-radius: 5px;
  background-color: #555;
  color: white;
  margin-bottom: 10px;
  box-sizing: border-box;
}

.chat-history-input-box button {
  padding: 10px;
  width: 100%;
  background-color: #61dafb;
  color: black;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.chat-history-input-box button:hover {
  background-color: #21a1f1;
}

.return-home-button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.return-home-button:hover {
  background-color: #45a049;
}
