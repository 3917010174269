/* SupplierProduct.css */

.supplier-product-container {
    padding: 20px;
    max-width: 1200px;
    margin-top: 50px;
    font-family: Arial, sans-serif;
  }
  
  .supplier-product-container h1 {
    text-align: center;
    color: #333;
    margin-bottom: 30px;
  }

  /* Return button styling */
  .back-button {
    display: inline-block;
    margin-bottom: 20px;
    margin-top: -80px;
    padding: 10px 20px;
    background-color: #2a9d8f;
    color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
    position: absolute;
    right: 20px; /* Positioning to the right side of the page */
  }

  .back-button:hover {
    background-color: #21867a;
    transform: translateY(-3px);
  }
  
  .products-list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
  }
  
  .product-card {
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 20px;
    width: 300px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    background-color: #fff;
  }
  
  .product-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }
  
  .product-image {
    width: 100%;
    height: auto;
    border-radius: 10px;
    margin-bottom: 15px;
  }
  
  .product-details {
    text-align: center;
  }
  
  .product-details h3 {
    font-size: 1.5rem;
    margin: 10px 0;
    color: #555;
  }
  
  .product-details p {
    font-size: 1rem;
    color: #777;
    margin: 5px 0;
  }
  
  /* Price and stock details styling */
  .product-details .price {
    font-weight: bold;
    color: #2a9d8f;
  }
  
  .product-details .stock {
    font-weight: bold;
    color: #e76f51;
  }
