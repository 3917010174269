/* Scoped to FloristPageApp component */
.signup-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background-color: #282c34;
    padding: 20px;
    box-sizing: border-box;
    color: white;
}
  
.florist-form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 500px;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 10px;
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-top: -400px;
}
  
.florist-signup-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
  
.florist-form-group {
    margin-bottom: 15px;
    width: 100%;
}
  
.florist-form-group label {
    display: block;
    margin-bottom: 5px;
    margin-right: 250px;
    font-weight: bold;
    color: white;
}
  
.florist-form-group input {
    width: 100%;
    padding: 8px;
    border-radius: 5px;
    border: 1px solid #ccc;
    background-color: #555;
    color: white;
}
  
.florist-checkbox-group {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    margin-left: 15px;
    gap: 8px;
    justify-content: flex-start;
}
  
.florist-checkbox-group input[type="checkbox"] {
    margin-left: -250px;
    margin-right: -240px;
    margin-bottom: 7px;
}
  
.florist-footer {
    margin-top: 20px;
}
  
.florist-btn {
    background-color: #ffccdd;
    color: black;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    margin-top: 15px;
}
  
.florist-btn:hover {
    background-color: #ffccdd;
}

.welcome-message {
    font-size: 1.5rem;
    color: #ffccdd;
}

/* Media Query for Phone View */
@media (max-width: 768px) {
    .signup-page {
        padding: 10px; /* Adjust padding for smaller screens */
    }
  
    .florist-form-container {
        max-width: 90%; /* Occupy most of the screen width */
        padding: 15px; /* Adjust padding */
    }
  
    .florist-form-group label {
        font-size: 0.8rem; /* Smaller font for labels */
    }
  
    .florist-form-group input {
        font-size: 0.8rem; /* Adjust input font size */
        padding: 6px; /* Reduced padding */
    }
  
    .florist-btn {
        font-size: 0.8rem; /* Smaller button text */
        padding: 6px 12px; /* Reduced button padding */
    }
  
    .welcome-message {
        font-size: 1rem; /* Smaller welcome message */
    }
}