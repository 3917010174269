/* Customer Management Page Styles */
.customer-management-page {
  padding: 20px;
  max-width: 1000px;
  margin: 0 auto;
}

.customer-management-page .customer-management-header {
  font-size: 24px;
  margin-bottom: 20px;
  color: #333;
}

.customer-management-page table {
  width: 100%;
  border-collapse: collapse;
}

.customer-management-page th,
.customer-management-page td {
  border: 1px solid #ddd;
  padding: 12px;
  text-align: left;
}

.customer-management-page th {
  background-color: #f2f2f2;
}

.customer-management-page .customer-management-thumbnail {
  max-width: 50px;
  border-radius: 50%;
}

.customer-management-page .btn {
  background-color: #ff66b2;
  color: white;
  padding: 8px 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.customer-management-page .btn:hover {
  background-color: #ff3385;
}

.customer-management-page input[type="text"] {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}
