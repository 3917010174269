/* Container for customer profile */
.customer-profile-container {
    width: 100%;
    max-width: 800px;
    margin: 20px auto;
    padding: 30px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
}

/* Title styling */
.customer-profile-container h2 {
    color: #333;
    margin-bottom: 25px;
    font-size: 26px;
    font-weight: 600;
    border-bottom: 2px solid #ff66b2;
    padding-bottom: 10px;
}

/* Ensure h4 follows custom styles */
.customer-profile-container h4 {
    font-size: 20px; /* Adjusted size */
    color: #ff66b2; /* Pink color to match theme */
    font-weight: 600; /* Emphasized heading */
    margin-top: 15px; /* Add margin for separation */
    margin-bottom: 10px; /* Ensure space below */
}

/* Account info section */
.account-info {
    margin-bottom: 40px;
}

/* Paragraph styling */
.account-info p {
    font-size: 18px;
    margin: 8px 0;
    color: #555;
}

/* Profile picture */
.profile-picture {
    max-width: 150px;
    border-radius: 50%;
    margin-top: 15px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

/* Password change section */
.password-change-section {
    margin-top: 20px;
}

.password-change-section input {
    padding: 10px;
    margin-right: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
}

.password-change-section button {
    padding: 10px 15px;
    background-color: #ff66b2;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
}

.password-change-section button:hover {
    background-color: #ff3385;
}

/* Order history */
.order-history {
    margin-top: 30px;
}

.order-item {
    background-color: #fff;
    border: 1px solid #ddd;
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.08);
}

.order-item p {
    margin: 6px 0;
    font-size: 16px;
    color: #333;
}

.order-details h4 {
    margin-top: 15px;
    font-size: 20px;
    color: #ff66b2;
    font-weight: 600;
}

.order-item-detail p {
    margin-left: 20px;
    font-size: 15px;
    color: #666;
}

/* Error message */
.error-message {
    color: red;
    font-size: 18px;
    text-align: center;
    margin-top: 20px;
}
