/* Distinctive ChatWidget Styles */

.chat-widget-unique {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 300px;
  max-height: 400px;
  transition: all 0.3s ease;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  z-index: 1000;
}

.chat-widget-unique.expanded {
  width: 300px;
  max-height: 400px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease; /* Smooth transition */
}


.chat-header-unique {
  background-color: #333;
  color: white;
  padding: 10px;
  text-align: center;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.end-chat-button-unique {
  background-color: #ffccdd;
  border: none;
  color: white;
  font-size: 10px; /* Smaller font size */
  cursor: pointer;
  padding: 5px 10px;
  border-radius: 5px;
  margin-left: 10px;
}

.end-chat-button-unique:hover {
  background-color: #ff99aa; /* Slightly darker on hover */
}

.chat-body-unique {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 10px;
  height: 100%;
  overflow-y: auto;
}

.messages-unique {
  flex-grow: 1;
  overflow-y: auto;
  margin-bottom: 10px;
}

.message-unique {
  display: block;
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 5px;
  max-width: 80%;
  word-break: break-word;
}

.message-sent-unique {
  background-color: #61dafb;
  align-self: flex-end;
  text-align: left;
}

.message-received-unique {
  background-color: #ddd;
  align-self: flex-start;
  text-align: left;
}

.message-unique p {
  margin: 0;
}

.message-meta-unique {
  margin-top: 5px;
  font-size: 0.85em;
  color: #666;
  text-align: right;
}

.typing-status-unique {
  font-size: 0.9em;
  color: #777;
}

/* Make input and button unique to ChatWidget */
.chat-widget-unique .input-unique {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-bottom: 10px;
}

.chat-widget-unique .button-unique {
  padding: 10px;
  background-color: #ffccdd;
  border: none;
  border-radius: 5px;
  color: white;
  cursor: pointer;
}

.chat-widget-unique .button-unique:hover {
  background-color: #ff99aa;
}

.chat-widget-unique.collapsed {
  width: 60px; /* 20% of 300px */
  height: 60px;
  max-height: 60px;
  overflow: hidden;
  border-radius: 50%; /* Circular look for collapsed state */
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #333;
  color: white;
  font-size: 12px;
  text-align: center;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.chat-widget-unique.collapsed .chat-body-unique,
.chat-widget-unique.collapsed .chat-header-unique {
  visibility: hidden; /* Hide content in collapsed state */
  pointer-events: none;
}