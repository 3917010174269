.customer-profile-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background-color: #ffc0cb;
    color: #333;
    padding: 20px;
  }
  
  .customer-profile-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 100%;
    max-width: 500px;
    background-color: rgba(255, 255, 255, 0.9);
    padding: 20px;
    border-radius: 10px;
  }
  
  .customer-profile-form input {
    padding: 12px;
    border-radius: 5px;
    border: 1px solid #ddd;
  }
  
  .file-upload-label {
    margin-top: 15px;
    font-weight: bold;
  }
  
  .error {
    color: red;
  }
  