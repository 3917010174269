/* index.css */

/* Specific styles for unique header content */
.header-content-unique {
  background-color: transparent; /* Make header background transparent */
  padding: 20px;
  text-align: center;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
}

/* Logo styling */
.app-logo,
.client-app-logo {
  height: 10vmin;
  width: auto;
  margin: 0 auto;
  display: block;
}

/* Slogan styling */
.slogan,
.client-slogan {
  font-size: 2rem;
  color: #61dafb; /* Light blue color for slogan */
  margin: 10px 0;
  text-align: center;
}
