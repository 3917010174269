.florist-login-page {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: #ffe6f0; /* Light pink background similar to the header */
  color: #333; /* Keep text color dark for readability */
  padding: 20px;
}

.florist-login-page h2 {
  color: #ff66b2; /* Pink shade for the header title */
  margin-bottom: 20px;
  font-size: 2rem;
}

.florist-login-page form {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff; /* White form background for contrast */
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Light shadow */
}

.florist-login-page input {
  padding: 10px;
  margin: 10px;
  font-size: 1rem;
  border-radius: 5px;
  border: 1px solid #ccc;
  width: 250px;
  background-color: #fff; /* Keep input fields white for readability */
}

.florist-login-page .btn {
  background-color: #ff66b2; /* Pink button matching the header */
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  margin-top: 15px;
  transition: background-color 0.3s ease;
}

.florist-login-page .btn:hover {
  background-color: #ff3385; /* Darker pink hover effect */
}

.florist-login-page .signup-btn {
  background-color: #ffccdd; /* Lighter pink for the secondary button */
  color: white;
  margin-top: 20px;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.florist-login-page .signup-btn:hover {
  background-color: #ff99bb; /* Darker pink for hover state */
}

